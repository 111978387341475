

import * as React from 'react'
import { CustomCard } from './Card';
import { ActivityLog, ActivityType } from '../../types/FB-Activity-Types';
import { unixToDateTime } from '../../globals/date-converters';

import FBWWWW from '../../assets/FBWWW.svg'
import { HorizontalFlexDiv, HorizontalFlexDivSpaceBetween } from '../templates/Wrappers';

export type DaliyActivities = {
    name: string;
    activities: ActivityLog[];
}

type Props = {
    activity: DaliyActivities
}

const getName = (activityType: ActivityType) => {

    if (activityType === ActivityType.ProjectVisit) return "Project visit"
    if (activityType === ActivityType.FeedVisit) return "Feed visit"

}

const textStyles = { fontSize: 12, lineHeight: 1.4 }

export const ActivityItem: React.FC<Props> = ({ activity }) => {

    const { activities, name } = activity

    return (
        <CustomCard style={{ marginLeft: 0, paddingLeft: 15 }}>
            <h5 style={{ marginBottom: 0 }}>{name}</h5>
            {activities.map((activity) => (
                <HorizontalFlexDivSpaceBetween style={{ marginTop: 0 }}>
                    <HorizontalFlexDiv>
                        <img alt='fb-www' style={{ width: 15, height: 15, marginTop: 0, marginRight: 10, opacity: 0.4 }} src={FBWWWW} />
                        <span style={{ marginRight: 4, ...textStyles }}>{getName(activity.activityType)}</span>
                        <a style={textStyles} href={'/projects/' + activity.refrenceId}>{activity.refrenceId}</a>
                    </HorizontalFlexDiv>
                    <span style={textStyles} >{unixToDateTime(activity.chunkUnix)}</span>

                </HorizontalFlexDivSpaceBetween>
            ))}
        </CustomCard>

    );
}