import * as React from 'react';
import { useHistory } from 'react-router';
import { numberWithCommas } from '../../globals/money-converter';
import { Investment, investmentStatusToText } from '../../types/FB-Investment-Types';
import { NoResultBadge } from '../atoms/NoResultBadge';
import { CustomChip } from '../atoms/StatusChip';
import { CustomCard } from '../molecules/Card';
import { HorizontalFlexDivSpaceBetween, VerticalFlexDiv } from '../templates/Wrappers';
import { investmentStatusToColor, paymentStatusToColor } from './InvestmentDetails';

type Props = {
    investments: Investment[]
}

export const InvestmentList: React.FC<Props> = ({ investments }) => {

    const history = useHistory()

    const goToInvestment = (owner: string, project: string) => {
        history.push('/users/' + owner + '/' + project)
    }

    if(investments.length===0) return <NoResultBadge/>

    investments = investments.sort((b, a)=> a.created - b.created)

    return (
        <div>
            {investments.map((investment: Investment, key: number) => (
                <CustomCard key={key} style={{ marginLeft: 0, paddingLeft: 15 }} onClick={() => goToInvestment(investment.userId, investment.projectId)}>
                    <HorizontalFlexDivSpaceBetween>
                        <VerticalFlexDiv>
                            <h5 style={{ marginBottom: 0 }}>{numberWithCommas(investment.investmentAmount)} {investment.currency}</h5>
                            <p style={{ marginTop: 2, marginRight: 20 }}>{investment.projectId}</p>
                            <p style={{ fontSize: 10, marginTop: 2 }}>Oprettet: {(new Date(investment.created)).toLocaleDateString()}</p>
                        </VerticalFlexDiv>
                        <VerticalFlexDiv>
                            <CustomChip type={investmentStatusToColor(investment.investmentStatus)}
                                label={investmentStatusToText(investment.investmentStatus).toUpperCase()} />
                            <CustomChip type={paymentStatusToColor(investment.paymentDate)} label={investment.paymentDate === -1 ? "AFVENTER" : "BETALING MODTAGET"} />
                        </VerticalFlexDiv>
                    </HorizontalFlexDivSpaceBetween>
                </CustomCard>
            ))}
        </div>
    )
}
