import { Breadcrumbs, Divider, Link, Typography } from '@material-ui/core';
import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { StatItem } from '../../components/atoms/StatItem';
import { ChipTypes, CustomChip } from '../../components/atoms/StatusChip';
import { ActionList, actionOptions } from '../../components/molecules/ActionList';
import { CustomCard } from '../../components/molecules/Card';
import { CustomImage } from '../../components/molecules/Image';
import { Loading } from '../../components/molecules/Loading';
import { GroupingOptions, TimeChart } from '../../components/molecules/TimeChart';
import { DynamicDataTable } from '../../components/organisms/DynamicDataTable';
import { BodyWithTopBar } from '../../components/templates/BodyWithTopBar';
import { CustomSection, VerticalFlexDiv } from '../../components/templates/Wrappers';
import { getProjectDetailedById } from '../../globals/ApiHooks';
import { numberWithCommas } from '../../globals/money-converter';
import { InvestmentTableDataConverter } from '../../globals/TableDataConverters';
import { InvestmentStatus } from '../../types/FB-Investment-Types';
import { ProjectStatus, DetailedProject } from '../../types/FB-Project-Types';


export const getChipStaus = (projectStaus: ProjectStatus) => {
    if (projectStaus === ProjectStatus.ACTIVE) return <CustomChip label='Aktivt' type={ChipTypes.Warning} />
    if (projectStaus === ProjectStatus.COMING_SOON) return <CustomChip label='Comming soon' type={ChipTypes.Inactive} />
    if (projectStaus === ProjectStatus.COMPLETED) return <CustomChip label='Completed' type={ChipTypes.Sucess} />
    if (projectStaus === ProjectStatus.DRAFT) return <CustomChip label='Draft' type={ChipTypes.Inactive} />
    if (projectStaus === ProjectStatus.CANCELLED) return <CustomChip label='Draft' type={ChipTypes.Error} />
}


export const DetailedProjectPage: React.FC = () => {
    const history = useHistory()
    let { id } = useParams<{ id: string }>();
    const [project, setProject] = React.useState<DetailedProject>()

    const getProject = async () => {
        if (!id) return
        let result = await getProjectDetailedById(id)
        if (result) setProject(result)
    }

    const goToInvestment = (id: string) => {
        history.push('/projects/investment/' + id)
    }

    //eslint-disable-next-line
    React.useEffect(() => { getProject() }, [id])


    if (!project) return <Loading />

    const size = project.nonDraftInvestment.length
    const investmentSum = project.nonDraftInvestment.reduce((acc, item) => item.investmentStatus> InvestmentStatus.PENDING_AML ? acc + item.investmentAmount : acc, 0)

    const actions = [
        { type: actionOptions.CONTENT, title: "Edit information", link: '/projects/edit/' + id },
        { type: actionOptions.CONTENT, title: "Edit content", link: '/projects/edit/' + id },
        { type: actionOptions.CONTENT, title: "Edit media", link: '/projects/edit/' + id },
        { type: actionOptions.CONTENT, title: "Edit contract", link: '/projects/edit/' + id },
        { type: actionOptions.LINE },
        { type: actionOptions.CONTENT, title: "Payments", link: '/projects/payments/' + id },
        { type: actionOptions.LINE },
        { type: actionOptions.CONTENT, title: "View on marketingsite", link: 'https://www.fundbricks.com/projects/' + id },
        { type: actionOptions.CONTENT, title: "View on app", link: 'https://app.fundbricks.com/projects/' + id },
        { type: actionOptions.LINE },
        { type: actionOptions.CONTENT, title: "Create notification", link: '/' }
    ]

    return (
        <BodyWithTopBar>
            <CustomSection container column style={{ marginTop: 10 }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/">Dashboard</Link>
                    <Link color="inherit" href="/projects">Projects</Link>
                    <Typography color="textPrimary">{project.content.title}</Typography>
                </Breadcrumbs>
                {getChipStaus(project.projectStatus)}
                <h1>{project.content.title}</h1>
            </CustomSection>
            <CustomSection container>
                <VerticalFlexDiv style={{ flexGrow: 1 }}>
                    <CustomImage style={{ maxWidth: 250 }} src={project.media.images[0]} />
                    <CustomCard>
                        <h3>Information</h3>
                        <StatItem name='Interests rate' content={(project.investment.return * 100).toFixed(2)  + ' %'} />
                        <StatItem name='Coverage percentage' content={(project.finance.economy.coverage_percentage * 100).toFixed(2) + ' %'} />
                        <StatItem name='Current funding DKK' content={numberWithCommas(project.investment.currentFunding) + ' DKK'} />
                        <StatItem name='Duration' content={project.investment.duration.min + ' to ' + project.investment.duration.max + ' months'} />
                        <Divider />
                        <StatItem name='Interests count' content={project.interestCount || 'n/a'} />
                        <StatItem name='Investment sum DKK' content={numberWithCommas(investmentSum) + ' DKK'} />
                    </CustomCard>
                    <CustomCard>
                        <h3>ACTIONS</h3>
                        <ActionList actions={actions} />
                    </CustomCard>
                </VerticalFlexDiv>
                <VerticalFlexDiv style={{ flexGrow: 2 }}>
                    <CustomCard>
                        <h3>Investments over time</h3>
                        <TimeChart grouping={GroupingOptions.DATE} unixDates={(project.nonDraftInvestment.map((i) => i.created))} />
                    </CustomCard>
                    <CustomCard>
                        <DynamicDataTable tableSize={size} title='Investments' tableData={InvestmentTableDataConverter(project.nonDraftInvestment)}
                            customLength={20} onRowPress={goToInvestment} csvFileName={project.projectId}/>
                    </CustomCard>
                </VerticalFlexDiv>
            </CustomSection>
        </BodyWithTopBar>
    )
}