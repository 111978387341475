import * as React from 'react'
import { VictoryChart, VictoryLine, VictoryTheme } from 'victory';
import { primary } from '../../globals/colors';


type DataEntity = {
    date: string;
    mutation: number;
    sortableKey: number;
}

type Props = {
    unixDates: number[];
    grouping: GroupingOptions;
}
export enum GroupingOptions {
    HOUR = 'hour',
    DATE = 'date',
}


const oneDay = 86400000

const format = (time: number, grouping: GroupingOptions) => {
    const hour = (new Date(time)).getHours()
    const date = (new Date(time)).getDate()
    const month = (new Date(time)).getMonth() + 1
    if (grouping === GroupingOptions.DATE) return date + '/' + month
    return hour + ''
}

const MAXSIZE = 24

export const TimeChart: React.FC<Props> = ({ unixDates, grouping }) => {
    const [data, setData] = React.useState<DataEntity[]>([])
    const [maxPadding, setMaxPadding] = React.useState<number>(100)

    const formatDataset = (dates: number[]) => {
        //Dynamically creates sizes based on group options
        const max = dates.reduce((acc, item) => item > acc ? item : acc, dates[0])
        const min = dates.reduce((acc, item) => item < acc ? item : acc, dates[0])
        let groupper = MAXSIZE
        if (grouping === GroupingOptions.DATE) groupper = Math.round((max - min) / oneDay)
        const group = Math.round((max - min) / groupper)

        // Populate
        let dataset: DataEntity[] = []
        for (let index = 0; index < groupper + 1; index++) {
            const item = min + (group * index)
            dataset.push({ date: format(item, grouping), mutation: 1, sortableKey: index })
        }
        // Insert
        dates.forEach((dateItem) => {
            const date = format(dateItem, grouping)
            let datasetItem: DataEntity = dataset.filter((i) => i.date === date)[0]
            if (datasetItem) {
                datasetItem = { ...datasetItem, mutation: datasetItem.mutation + 1 }
                dataset = dataset.filter((i) => i.date !== date)
                dataset.push(datasetItem)
            }
        })
        //Halving
        while (dataset.length > MAXSIZE) {
            let acc: DataEntity[] = []
            // eslint-disable-next-line
            dataset.forEach((current, key) => {
                if (key % 2 === 1) return;
                let next = dataset[key + 1]
                if (!next) acc = [...acc, current]
                else acc = [...acc, { ...next, mutation: next.mutation + current.mutation }]
            })
            dataset = acc
        }
        dataset = dataset.sort((a, b) => a.sortableKey - b.sortableKey)
        dataset[dataset.length - 1] = { ...dataset[dataset.length - 1], date: 'Now' }
        const maxPad =  dataset.reduce((acc, item) => item.mutation > acc ? item.mutation : acc, dataset[0].mutation)+5
        setMaxPadding(maxPad)
        return dataset
    }

    React.useEffect(() => {
        if(unixDates && unixDates.length>0) setData(formatDataset(unixDates))
        // eslint-disable-next-line
    }, [unixDates])

    if (!unixDates || unixDates.length===0 || data.length < 3) return <p>Not enough items</p>

    return (
        <VictoryChart minDomain={{ y: -1 }} maxDomain={{ y: maxPadding }} theme={VictoryTheme.material} height={300} width={700}>
            <VictoryLine data={data} x="date" y="mutation" interpolation="natural"
                style={{ data: { strokeWidth: 4, stroke: primary } }} />
        </VictoryChart>
    )
}