import { Button, LinearProgress } from '@material-ui/core'
import * as React from 'react'
import styled from 'styled-components'
import { normal } from '../../globals/colors'
import { HorizontalFlexDiv } from '../templates/Wrappers'


export const DefaultButton = styled.button`
    padding: 18px 32px;
    border-radius: 10px;
    background-color: #302cff;
    box-shadow: 0 2px 8px 0 rgba(48, 44, 255, 0.1);
    transition: box-shadow 350ms ease, background-color 350ms ease, -webkit-transform 350ms ease;
    transition: transform 350ms ease, box-shadow 350ms ease, background-color 350ms ease;
    transition: transform 350ms ease, box-shadow 350ms ease, background-color 350ms ease, -webkit-transform 350ms ease;
    color: #fff;
    line-height: 1em;
    font-weight: 500;
    text-align: center;
    font-family: "Plus Jakarta Display", sans-serif;
    border-width: 0px;
    &:hover {
        box-shadow: 0px 1px 12px 2px rgba(21, 0, 255, 0.396);
        transform: scale(0.95);
        color: #fff;
    }
    &:focus {
        outline: none;
    }
    &:active {
        transform: scale(0.999);
    }
`

type Props = {
    onClick: () => (Promise<void> | void)
    disable?: boolean
}

export const DefaultButtonWithLoading: React.FC<Props> = ({ onClick, children, disable }) => {

    const [loading, setLoading] = React.useState(false)

    const handleClick = async () => {
        if (loading) return;
        setLoading(true)
        await onClick()
        setLoading(false)
    }

    return (
        <DefaultButton onClick={handleClick} disabled={!!disable} style={disable ? { backgroundColor: normal } : {}}>
            {loading ? <LinearProgress style={{ height: 3, margin: 5 }} color='secondary' /> : children}
        </DefaultButton>
    )
}


type ButtonWithConfirmStepProps = {
    onClick: () => (Promise<void> | void)
    label: string
}

export const ButtonWithConfirmStep: React.FC<ButtonWithConfirmStepProps> = ({ onClick, label }) => {

    const [showConfirmation, setShowConfirmation] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const handleClick = async () => {
        if (loading) return;
        setLoading(true)
        await onClick()
        setLoading(false)
        setShowConfirmation(false)
    }

    if(loading) return <LinearProgress style={{ height: 3, margin: 5 }} color='secondary' />

    if (showConfirmation) return (
            <HorizontalFlexDiv>
                <Button onClick={()=> setShowConfirmation(false)} style={{marginRight: 10}}>
                    Cancel
                </Button>
                <Button onClick={handleClick} variant='contained' color='primary'>
                    I'm sure
                </Button>
            </HorizontalFlexDiv>
    )
    return (
        <HorizontalFlexDiv>
            <Button onClick={() => setShowConfirmation(true)} variant='contained' color='primary'>
                {label}
            </Button>
        </HorizontalFlexDiv>

    )
}