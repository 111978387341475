import * as React from 'react'
import { registrerPayment } from '../../globals/ApiHooks';
import { unixToDateTime } from '../../globals/date-converters';
import { Investment } from '../../types/FB-Investment-Types';
import { ButtonWithConfirmStep } from '../atoms/SampleButton';
import { CustomChip } from '../atoms/StatusChip';
import { VerticalFlexDiv } from '../templates/Wrappers';
import { ActivityItem } from './InvestmentActivityItem';


type Props = {
    investment: Investment;
    reload?: ()=>Promise<void>;
}

export const InvestmentSteps: React.FC<Props> = ({ investment, reload }) => {

    const step = investment.step

    const onRegistrerPayment = async () => { 
        await registrerPayment(investment.projectId, investment.userId)
        if(reload) await reload() 
    }

    return (
        <VerticalFlexDiv>
            <ActivityItem heading="Money transfered" checked={investment.paymentDate > 0}
                date={(investment.paymentDate > 0 && unixToDateTime(investment.paymentDate)) || ''}>
                <span>Payment refrence: <CustomChip label={investment.userId.slice(-8)} /></span>
                {investment.paymentDate < 0 && <ButtonWithConfirmStep label='Register payment' onClick={onRegistrerPayment} />}
            </ActivityItem>
            <ActivityItem heading="Contract signed" checked={!!investment.agreementDate}
                date={(investment.agreementDate && unixToDateTime(investment.agreementDate)) || ''} />
            <ActivityItem checked={step > 6} heading="Terms and conditions" />
            <ActivityItem checked={step > 5} heading="Contact person" />
            <ActivityItem checked={step > 4} heading="Fund details" />
            <ActivityItem checked={step > 3} heading="Investor bank" />
            <ActivityItem checked={step > 2} heading="Investor documentation" />
            <ActivityItem checked={step > 1} heading="Investor details" />
            <ActivityItem checked heading="Amount selected" />
            <ActivityItem checked heading="Investment started" date={unixToDateTime(investment.created)} />
        </VerticalFlexDiv>
    );
}