import { ActivityLog } from "./FB-Activity-Types"
import { Investment, InvestmentWithInvestor } from "./FB-Investment-Types"

export type DetailedProject = Project & {
    nonDraftInvestment: InvestmentWithInvestor[];
    draftInvestment: Investment[];
    activity: ActivityLog[];
}


export enum ProjectStatus {
    DRAFT = -1,
    ACTIVE = 0,
    COMING_SOON = 1,
    COMPLETED = 2,
    CANCELLED = 3
}

export type Project = {
    projectId: string;
    hidden: boolean;
    investors: number;
    projectStatus: ProjectStatus;
    projectType: string;
    terms: Terms;
    content: Content;
    finance: Finance;
    investment: ProjectInvestment;
    interestCount?: number;
    media: Media;
}

export type Content = {
    bank: Statement;
    bankreturn: Statement;
    crowdinvestor: Statement;
    crowdinvestorreturn: Statement;
    developer: Developer;
    developerreturn: Statement;
    economy: Statement;
    fb: Statement;
    investment: Statement;
    payback: Statement;
    projectcompany: Projectcompany;
    review: Statement;
    risks: Statement;
    sold: Statement;
    teaser: string;
    title: string;
    vault: Vault;
}

export type Statement = {
    text: string;
}

export type Developer = {
    accountant: string;
    company_formation_date: number;
    company_id: string;
    company_structure: string;
    latest_annual_report: string;
    management: Management[];
    name: string;
    text: string;
}

export type Management = {
    image: string;
    name: string;
    position: string;
    text: string;
}


export type Projectcompany = {
    address: string;
    formation: number;
    id: string;
    name: string;
    text: string;
}

export type Vault = {
    account: string;
    text: string;
    title: string;
    url: string;
}

export type Finance = {
    economy: Economy;
}

export type Economy = {
    coverage_percentage: number;
}

export type ProjectInvestment = {
    currentFunding: number;
    deadline: number;
    duration: Duration;
    minimum: number;
    return: number;
    target: number;
}

export type Duration = {
    max: number;
    min: number;
}

export type Media = {
    images: string[];
    video: string[];
}

export type Terms = {
    issueDate: number;
    endDate: number;
    loanAgreementTemplateId: string;
    prod_loanAgreeementTemplateId: string;
    summary: string;
}