type ApiEndpoint = {
    name: string,
    endpoint: string,
    region: string
}

type CognitoConfigs = {
    mandetorySignIn: boolean
    region: string
    userPoolId: string
    identityPoolId: string
    userPoolWebClientId: string
}

type StorageConfig = {
    region: string
    bucket: string
    identityPoolId: string
}

type AppConfigs = {
    Auth: CognitoConfigs,
    Storage?: StorageConfig,
    API: {
        endpoints: ApiEndpoint[]
    }

}

const region = "eu-central-1"
export const AdminApi2 = 'FB-Admin-API-2'
const defaultconfig: AppConfigs = {
    Auth: {
        mandetorySignIn: true,
        region,
        userPoolId: "eu-central-1_ojIRaL4FJ",
        identityPoolId: "eu-central-1:23054d1a-11cc-4d0b-b8b3-793a5925c199",
        userPoolWebClientId: "654gp3sshvq71034sdgd9nl7tq",
    },
    API: {
        endpoints: [{
            name: AdminApi2,
            endpoint: process.env.NODE_ENV === 'development' ? 'http://localhost:3200/dev' : 'https://9no1tngvf1.execute-api.eu-central-1.amazonaws.com/prod',
            region,
        }]
    }
}

export default defaultconfig