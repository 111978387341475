import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { Project } from '../../types/FB-Project-Types'
import { ProjectCard } from '../atoms/ProjectCard'


export const ProjectList: React.FC<{ projects: Project[] }> = ({ projects }) => {

    projects = projects.sort((b, a) => a.terms.issueDate - b.terms.issueDate)
    const history = useHistory()

    const goToProject = (projectId: string) => {
        history.push('/projects/' + projectId)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent:'center' }}>
            {projects.map((project, key) => (
                <ProjectCard goToProject={goToProject} key={key} project={project} />
            ))}
        </div>
    )
}
