import * as React from 'react'
import { CustomSection, HorizontalFlexDiv } from '../templates/Wrappers';
import { DetailedProject } from '../../types/FB-Project-Types';
import { Column, CustomMaterialTable } from '../molecules/CustomMaterialTable';
import { CustomCard } from '../molecules/Card';
import { OpenInNew } from '@material-ui/icons';
import { Action } from 'material-table';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

type Props = {
    project: DetailedProject,
}


enum FilterState {
    all, notPaid, paid
}

const investmentColumns: Column[] = [
    { title: 'First name', field: 'fname' },
    { title: 'Last name', field: 'lname' },
    { title: 'Payment', field: 'paymentStatus' },
    { title: 'Amount', field: 'investmentAmount' },
    { title: 'Currency', field: 'currency' },
    { title: 'Reference', field: 'reference' },
    { title: 'Bank Reg', field: 'bankregno' },
    { title: 'Bank no', field: 'bankaccountno' },
]

export const PaymentsList: React.FC<Props> = ({ project }) => {

    const [data, setData] = React.useState<object[]>()
    const [filterState, setFilterState] = React.useState<FilterState>(FilterState.all)

    React.useEffect(() => {
        let list = project.nonDraftInvestment.map((investment) =>
        ({
            ...investment, reference: investment.userId.slice(-8),
            paymentStatus: investment.paymentDate > 0 ? 'Paid' : 'Not paid'
        })
        )
        if (filterState === FilterState.paid) list = list.filter((item) => item.paymentDate > 0)
        if (filterState === FilterState.notPaid) list = list.filter((item) => item.paymentDate < 0)
        setData(list)
    }, [filterState, project.nonDraftInvestment])

    const registrePayment: Action<Object> = {
        icon: OpenInNew,
        tooltip: 'Open in new window',
        onClick: (event: any, rowData: any) => {
            // Do save operation
            window.open('/projects/investment/' + rowData.userId + '/' + project.projectId)
        }
    }

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setFilterState(event.target.value as FilterState)
    }

    return (
        <CustomSection container column>
            <h1>{project.content.title}</h1>
            <CustomCard>
                <HorizontalFlexDiv style={{ flexDirection: 'row-reverse' }}>
                    <FormControl >
                        <InputLabel id="filter">Payment status</InputLabel>
                        <Select labelId="filter" id="demo-simple-select" value={filterState} onChange={handleChange} style={{width: 200}} >
                            <MenuItem value={FilterState.all}>All</MenuItem>
                            <MenuItem value={FilterState.paid}>Paid status</MenuItem>
                            <MenuItem value={FilterState.notPaid}>Non paid status</MenuItem>
                        </Select>
                    </FormControl>
                </HorizontalFlexDiv>
                <CustomMaterialTable data={data || []} title={'Payments for ' + project.content.title}
                    actions={[registrePayment]}
                    columns={investmentColumns} />
            </CustomCard>
        </CustomSection>
    )
}