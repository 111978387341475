import * as React from 'react'
import { BodyWithTopBar } from '../../components/templates/BodyWithTopBar';
import { CustomSection } from '../../components/templates/Wrappers';
import { CustomCard } from '../../components/molecules/Card';
import { DynamicDataTable, Row } from '../../components/organisms/DynamicDataTable';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { useAppContext } from '../../globals/loadingContext';
import { Investor } from '../../types/FB-User-Types';
import ApiHooks from '../../globals/ApiHooks';
import { InvestorTableDataConverter } from '../../globals/TableDataConverters';
import { ScanResult } from '../../types/API-Repsone-Types';

export const UserPage: React.FC = () => {
    const history = useHistory()

    const [investors, setInvestors] = React.useState<Investor[]>()
    const [size, setSize] = React.useState<number>()
    const setLoading = useAppContext()

    const loadUsersFromDatabase = async (lastKey?: string) => {
        setLoading(true)
        const result = await ApiHooks.getUsers(lastKey) as ScanResult<Investor> | undefined
        if (!result) return
        setSize(result.TableSize)
        setLoading(false)
        return result.Items
    }

    const goToUser = (userId: string) => {
        history.push('/users/' + userId)
    }

    const onSearch = async (term: string): Promise<Row[]> => {
        let result;
        if (!term || term.length === 0) result = await loadUsersFromDatabase()
        else result = await ApiHooks.searchUsers(term)
        if (!result) return []
        setInvestors(result)
        setSize(result.length)
        return InvestorTableDataConverter(result).bodyRows
    }

    const dynamicLoading = async (id: string) => {
        const result = await loadUsersFromDatabase(id)
        if (!result) return;
        setInvestors((investors) => investors ? [...investors, ...result] : result)
    }

    // eslint-disable-next-line
    React.useEffect(() => { (async () => { setInvestors(await loadUsersFromDatabase()) })() }, [])

    if (!investors) return <></>


    return (
        <BodyWithTopBar>
            <CustomSection container column style={{ marginTop: 10 }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/">Dashboard</Link>
                    <Typography color="textPrimary">Users</Typography>
                </Breadcrumbs>
            </CustomSection>
            <CustomSection container>
                <CustomCard style={{ flexGrow: 1 }}>
                    <DynamicDataTable title="Investors" tableData={InvestorTableDataConverter(investors || [])} customLength={50}
                        onRowPress={goToUser} tableSize={size || 0} onSearch={onSearch} dynamicLoading={dynamicLoading} />
                </CustomCard>
            </CustomSection>
        </BodyWithTopBar>
    )
}
