
import { Chip } from '@material-ui/core';
import * as React from 'react';


export enum ChipTypes { Inactive = 'Inactive', Sucess = 'Sucess', Warning = 'Warning', Error = 'Error' }

type Props = {
    label: string;
    type?: ChipTypes
}

export const CustomChip: React.FC<Props> = ({ label, type }) => {

    const statusToColor = () => {
        if (type === ChipTypes.Sucess) return '#34eba1'
        else if (type === ChipTypes.Error) return '#ff888c'
        else if (type === ChipTypes.Warning) return '#fffc67'
        else return '#dedede'
    }

    return <Chip label={label} size="small" style={{ backgroundColor: statusToColor(), margin: 4, minWidth: 160}} />
}