import * as React from 'react';
import { HorizontalFlexDivSpaceBetween } from '../templates/Wrappers';
//@ts-ignore
import Trend from 'react-trend';
import { normallight, primary } from '../../globals/colors';
import { NoResultBadge } from '../atoms/NoResultBadge';
import { ActivityLog } from '../../types/FB-Activity-Types';
import { ActivityItem, DaliyActivities } from '../molecules/UserActivityItem';

var weekday = new Array(7);
weekday[0]="Monday";
weekday[1]="Tuesday";
weekday[2]="Wednesday";
weekday[3]="Thursday";
weekday[4]="Friday";
weekday[5]="Saturday";
weekday[6]="Sunday";

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

type Props = {
    activityData: ActivityLog[]
}

type Month = {
    name: string;
    daliyActivities: DaliyActivities[];
}


const groupByMonth = (activityData: ActivityLog[]): Month[] => {
    let months: Month[] = []

    activityData.forEach(activity => {
        const time = new Date(activity.chunkUnix)
        const monthName = (monthNames[time.getMonth()]) + ' ' + time.getFullYear()
        const dayName = time.toLocaleDateString()+' - '+weekday[time.getDay()]

        let monthItem = months.filter((month) => month.name === monthName)[0]
        if (!monthItem) monthItem = { name: monthName, daliyActivities: [] }

        let dayItem = monthItem.daliyActivities.filter((day) => day.name === dayName)[0]
        if (!dayItem) dayItem = { name: dayName, activities: [] }

        dayItem.activities.push(activity)
        monthItem.daliyActivities = monthItem.daliyActivities.filter((day) => day.name !== dayName)
        monthItem.daliyActivities.push(dayItem)

        months = months.filter((month)=>month.name !== monthName) 

        months = [...months, monthItem]
    });


    return months
}


export const ActivityList: React.FC<Props> = ({ activityData }) => {
    if (activityData.length === 0) return <NoResultBadge />

    const months = groupByMonth(activityData)

    return (
        <div>
            {months.map((month: Month, key: number) => (
                <div key={key}>
                    <HorizontalFlexDivSpaceBetween>
                        <h3 style={{ marginLeft: 0 }}>{month.name}</h3>
                        {month.daliyActivities.length>2 && <Trend data={(month.daliyActivities.map((item)=>item.activities.length)).reverse()|| []} width={200} height={40}
                            smooth autoDraw autoDrawDuration={3000} autoDrawEasing="ease-out" radius={25}
                            strokeWidth={3} strokeLinecap={'round'} gradient={[normallight, primary]} />}
                    </HorizontalFlexDivSpaceBetween>
                    {month.daliyActivities.map((activity: DaliyActivities) => (
                        <ActivityItem activity={activity} />
                    ))}
                </div>
            ))}
        </div>
    )
}
