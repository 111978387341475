import * as React from 'react'
import { InputField } from '../atoms/InputField';
import { DefaultButtonWithLoading } from '../atoms/SampleButton';
import { TagList } from '../molecules/TagBubbleList';
import { Investor } from '../../types/FB-User-Types';
import { updateUser } from '../../globals/ApiHooks';
import { handleChange } from '../../globals/hooks';


type Props = {
    update: () => void
    investor: Investor
}

export const EditInvestor: React.FC<Props> = ({ investor, update }) => {
    const [fname, setFname] = React.useState(investor.fname)
    const [lname, setLname] = React.useState(investor.lname)
    const [email, setEmail] = React.useState(investor.email)
    const [phone, setPhone] = React.useState(investor.phone)

    const disable = !fname || fname === '' || !lname || lname === '' || !email || email === ''

    const handleUpdateUser = async () => {
        if (disable) return;
        await updateUser(investor.userId, { fname, lname, email })
        update()
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h5>Edit user information</h5>
            <InputField placeholder='Firstname' variant='small' margin onChange={handleChange(setFname)} value={fname} />
            <InputField placeholder='Lastname' variant='small' margin onChange={handleChange(setLname)} value={lname} />
            <InputField placeholder='Email' variant='small' margin onChange={handleChange(setEmail)} value={email} />
            <InputField placeholder='Phone' variant='small' margin onChange={handleChange(setPhone)} value={phone} />

            <TagList tags={investor.channels || []} onDelete={(id) => console.log(id)} />
            <DefaultButtonWithLoading disable={disable} onClick={handleUpdateUser}>SAVE CHANGES</DefaultButtonWithLoading>
        </div>
    )
}