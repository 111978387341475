import API from "@aws-amplify/api";
import { AdminApi2 } from "../config";
import { Dashboard, ScanResult } from "../types/API-Repsone-Types";
import { Project, DetailedProject } from "../types/FB-Project-Types";
import { Investor, InvestorWithInvestments, UpdateInvestorParams } from "../types/FB-User-Types";
import { setError } from "./hooks";

export const getUsers = async (lastEvaluatedKey?: string): Promise<ScanResult<Investor> | undefined> => {
    try {
        return await API.post(AdminApi2, '/investor/list', { body: { lastEvaluatedKey } }) as ScanResult<Investor>
    } catch (error) {
        setError(error.message)
    }
    return undefined
}

export const searchUsers = async (term: string): Promise<Investor[] | undefined> => {
    try {
        return await API.get(AdminApi2, '/investor/search/' + term, {}) as Investor[]
    } catch (error) {
        setError(error.message)
    }
    return undefined
}

export const getUserById = async (id: string): Promise<InvestorWithInvestments | undefined> => {
    try {
        return await API.get(AdminApi2, '/investor/' + id, {}) as InvestorWithInvestments
    } catch (error) {
        setError(error.message)
    }
    return undefined
}

export const getProjectById = async (id: string): Promise<Project | undefined> => {
    try {
        const path = '/project/' + id
        return await API.get(AdminApi2, path, {}) as Project
    } catch (error) {
        setError(error.message)
    }
    return undefined
}

export const getProjectDetailedById = async (id: string): Promise<DetailedProject | undefined> => {
    try {
        const path = '/project/detailed/' + id
        return await API.get(AdminApi2, path, {}) as DetailedProject
    } catch (error) {
        setError(error.message)
    }
    return undefined
}


export const getProjects = async (): Promise<Project[] | undefined> => {
    try {
        return await API.get(AdminApi2, '/project', {}) as Project[]
    } catch (error) {
        setError(error.message)
    }
    return undefined
}

export const updateUser = async (id: string, params: UpdateInvestorParams): Promise<Investor | undefined> => {
    try {
        return await API.put(AdminApi2, '/investor/' + id, { body: { params } }) as Investor
    } catch (error) {
        setError(error.message)
    }
    return undefined
}

export const registrerPayment = async (projectId: string, userId: string): Promise<boolean | undefined> => {
    try {
        await API.post(AdminApi2, "/investment/payment", {
            body: { userId, projectId }
        })
        return true
    } catch (error) {
        setError(error.message)
    }
    return undefined
}


const getDashboard = async (): Promise<Dashboard | undefined> => {
    try {
        return await API.get(AdminApi2, '/dashboard', {}) as Dashboard
    } catch (error) {
        setError(error.message)
    }
    return undefined
}

export default { getUsers, getUserById, searchUsers, getProjects, getDashboard }