import * as React from 'react'
import { getChipStaus } from '../../content/Projects/DetailedProject'
import { Project } from '../../types/FB-Project-Types'
import { CustomCard } from '../molecules/Card'
import { ChipTypes, CustomChip } from './StatusChip'


const Row:React.FC<{title:string, content:string}> = ({title, content}) => (
    <tr>
        <td>{title}</td>
        <td style={{ textAlign: 'right' }}>{content}</td>
    </tr>
)

export const ProjectCard: React.FC<{ project: Project, goToProject: (projectId: string) => void }> = ({ project, goToProject }) => {

    const background = 'url(' + project.media.images[0] + ')'

    return (
        <CustomCard style={{ width: '20%', cursor: 'pointer' }} onClick={() => goToProject(project.projectId)}>
            <div style={{ height: 150, backgroundImage: background, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                {!project.hidden && getChipStaus(project.projectStatus)}
                {project.hidden && (<CustomChip label='Hidden' type={ChipTypes.Inactive} />)}
            </div>
            <h6>{project.content.title}</h6>
            <table style={{ width: '100%' }}>
                <Row title='Return' content={project.investment.return * 100 +'% '}/>
                <Row title='Investors' content={project.investors+''}/>
                {project.interestCount && <Row title='Interest count' content={project.interestCount+''}/>}

            </table>
        </CustomCard>
    )
}
