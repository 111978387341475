import * as React from 'react'
import styled from 'styled-components'
import { light } from '../../globals/colors'


const Badge = styled.div`
    background: ${light};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    border-radius: 4px;
`


export const NoResultBadge: React.FC = () => {
    return (
        <Badge>
            <span>NO RESULTS</span>
        </Badge>
    )
}