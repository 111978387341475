import { Button, LinearProgress } from '@material-ui/core'
import * as React from 'react'
import styled from 'styled-components';
import { lightest, normallight, light, darkest } from '../../globals/colors';
import { SearchInput } from '../molecules/SearchInput';
import { HorizontalFlexDiv, HorizontalFlexDivSpaceBetween } from '../templates/Wrappers';
//@ts-ignore
import { CSVLink } from "react-csv";

const TH: React.FC = ({ children }) => {
    return (
        <th style={{ textAlign: 'left', backgroundColor: lightest, color: darkest }}>
            {children}
        </th>)
}


export type Cell = string | number

export type Row = Cell[]

export type TableData = {
    head: string[]
    bodyRows: Row[]
}


const TableDataCell = styled.td<{ odd: boolean, hover: boolean }>`
    padding:0;
    cursor:pointer;
    background-color: ${(props) => (props.hover ? normallight : props.odd ? light : lightest)};

`

const TR: React.FC<{ row: Cell[], index: number, onRowPress?: (id: string) => void }> = ({ row, index, onRowPress }) => {
    const id = row[0].toString()
    row = row.slice(1, row.length)
    const [hover, setHover] = React.useState(false)

    const handleClick = () => onRowPress && onRowPress(id)

    return (
        <tr className="monospace" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={handleClick}>
            {row.map((cell, cellIndex) =>
                <TableDataCell key={cellIndex} odd={index % 2 === 0} hover={hover}>
                    {(cell || '').toString()}
                </TableDataCell>)}
        </tr>)
}

type DynamicDataTableProps = {
    tableData: TableData
    title: string
    customLength?: number
    tableSize: number
    onRowPress: (id: string) => void
    onSearch?: (term: string) => Promise<Row[]>
    hideTitle?: boolean
    csvFileName?: string
    dynamicLoading?: (lastEvaluatedKey: string) => Promise<void>
}


export const DynamicDataTable: React.FC<DynamicDataTableProps> = ({ tableData, title, customLength, onRowPress, tableSize, 
    onSearch, hideTitle, csvFileName, dynamicLoading }) => {
    const PAGE_LENGTH = customLength || 50

    const [page, setPage] = React.useState(0)
    const [rows, setRows] = React.useState<Row[]>(tableData.bodyRows.slice(0, PAGE_LENGTH))
    const [searchTerm, setSearchTerm] = React.useState<string>('')
    const [loading, setLoading] = React.useState(false)


    const handleSearch = () => {
        if (onSearch && searchTerm.length > 0) {
            (async () => {
                setLoading(true)
                const newRows = await onSearch(searchTerm)
                setRows(newRows.slice(0, PAGE_LENGTH))
                setLoading(false)
            })()
        }
    }

    const numberOfPages = Math.round(tableSize / PAGE_LENGTH)

    const changePage = (increment: boolean) => async () => {
        var newPage = page

        if (increment) newPage++
        else if (!increment) newPage--
        if (dynamicLoading && tableData.bodyRows.length < tableSize) {
            const id = rows[rows.length - 1][0].toString()
            await dynamicLoading(id)
        }
        setRows(tableData.bodyRows.slice(newPage * PAGE_LENGTH, (newPage + 1) * PAGE_LENGTH))
        setPage(newPage)
    }

    const csv = [['Id', ...tableData.head], ...tableData.bodyRows]


    return (
        <>
            {loading && <LinearProgress />}
            <HorizontalFlexDivSpaceBetween style={{ marginBottom: 10 }}>
                {!hideTitle && <h3>{title} - {rows.length} of total {tableSize} (Page {page + 1} of {numberOfPages === 0 ? 1 : numberOfPages})</h3>}
                <HorizontalFlexDiv>
                    {onSearch && <SearchInput searchPlaceholder="Search..." setTerm={setSearchTerm} onSearch={handleSearch} />}
                    {onSearch && <Button onClick={handleSearch} variant='text'>Søg</Button>}
                    {csvFileName && <Button><CSVLink data={csv} separator=';' filename={csvFileName + '-export.csv'}>Download csv</CSVLink></Button>}

                </HorizontalFlexDiv>
            </HorizontalFlexDivSpaceBetween>
            <table style={{ width: '100%', borderSpacing: 1, borderCollapse: 'collapse' }}>
                <thead><tr>{tableData.head.map((cell, headCellKey) => <TH key={headCellKey}>{cell}</TH>)}</tr></thead>
                <tbody>
                    {rows.map((bodyRow, index) => <TR onRowPress={onRowPress} row={bodyRow}
                        index={index + (page * PAGE_LENGTH)} key={index + (page * PAGE_LENGTH)} />)}
                </tbody>
            </table>
            <div style={{ margin: 10 }}>
                <HorizontalFlexDivSpaceBetween>
                    <Button size='small' disabled={page === 0} variant="contained" onClick={changePage(false)}>Previous page</Button>
                    <p>Page {page + 1} of {numberOfPages === 0 ? 1 : numberOfPages}</p>
                    <Button disabled={!numberOfPages || page === numberOfPages - 1} variant="contained" onClick={changePage(true)}>Next page</Button>
                </HorizontalFlexDivSpaceBetween>
            </div>

        </>
    )
}
