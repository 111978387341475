

import { Divider } from '@material-ui/core';
import * as React from 'react'
import { HorizontalFlexDiv } from '../templates/Wrappers';
import { CustomCard } from './Card';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

type Props = {
    heading: string;
    date?: string;
    checked?: boolean;
}

export const ActivityItem: React.FC<Props> = ({ heading, date, checked, children }) => {

    return (
        <HorizontalFlexDiv style={{ alignItems: 'center' }}>
            {checked ? <CheckCircleIcon color='primary' /> : <RadioButtonUncheckedIcon />}
            <div style={{ width: 40 }}>
                <Divider />
            </div>
            <CustomCard style={{ marginLeft: 0, paddingLeft: 15, width: 500}}>
                <h5 style={{ marginBottom: 0 }}>{heading}</h5>
                <p style={{ fontSize: 10, marginTop: 2 }}>{date}</p>
                {children}
            </CustomCard>
        </HorizontalFlexDiv>

    );
}