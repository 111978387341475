import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import * as React from 'react'
import { useParams } from 'react-router-dom';
import { StatItem } from '../../components/atoms/StatItem';
import { CustomCard } from '../../components/molecules/Card';
import { Loading } from '../../components/molecules/Loading';
import { ActivityList } from '../../components/organisms/ActivityList';
import { EditInvestor } from '../../components/organisms/EditInvestor';
import { InvestmentList } from '../../components/organisms/InvestmentList';
import { BodyWithTopBar } from '../../components/templates/BodyWithTopBar';
import { CustomSection, VerticalFlexDiv } from '../../components/templates/Wrappers';
import { getUserById } from '../../globals/ApiHooks';
import { InvestorWithInvestments } from '../../types/FB-User-Types';


export const DetailedUser: React.FC = () => {
    let { id } = useParams<{ id: string }>();
    const [investor, setInvestor] = React.useState<InvestorWithInvestments>()

    const getInvestor = async () => {
        if (!id) return
        const result = await getUserById(id)
        if (result) setInvestor(result)
    }

    //eslint-disable-next-line
    React.useEffect(() => { getInvestor() }, [id])

    if (!investor) return <Loading />

    const name = investor.fname + ' ' + investor.lname


    const createdAt = new Date(investor.createdAt)

    return (
        <BodyWithTopBar>
            <CustomSection container column style={{ marginTop: 10 }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/">Dashboard</Link>
                    <Link color="inherit" href="/users">Users</Link>
                    <Typography color="textPrimary">{name}</Typography>
                </Breadcrumbs>
            </CustomSection>
            <CustomSection container>
                <VerticalFlexDiv style={{ flexGrow: 2 }}>
                    <h1 style={{ marginBottom: 10 }}>{name}</h1>
                    {investor.companyname && (<Typography color="textPrimary">{investor.companyname} ({investor.companyid})</Typography>)}
                    <h2>Investments</h2>
                    <InvestmentList investments={investor.investmentObjects} />
                    <h2>Activity log</h2>
                    <ActivityList activityData={investor.activityLogObjects} />
                </VerticalFlexDiv>
                <VerticalFlexDiv style={{ flexGrow: 1, maxWidth: 500 }}>
                    <h2>User information</h2>
                    <CustomCard>
                        <EditInvestor update={getInvestor} investor={investor} />
                    </CustomCard>
                    <CustomCard>
                        <h3>Statistics</h3>
                        <StatItem name='Created at' content={createdAt.toLocaleDateString() + ' - ' + createdAt.toLocaleTimeString()} />
                        <StatItem name='Investments' content={investor.investmentObjects.length} />
                        <StatItem name='Activities' content={investor.activityLogObjects.length} />
                    </CustomCard>
                </VerticalFlexDiv>
            </CustomSection>
        </BodyWithTopBar>
    )
}