import { Row, TableData } from "../components/organisms/DynamicDataTable";
import { ActivityLogWithUser } from "../types/FB-Activity-Types";
import { investmentStatusToText, InvestmentWithInvestor } from "../types/FB-Investment-Types";
import { Project } from "../types/FB-Project-Types";
import { Investor } from "../types/FB-User-Types";
import { numberWithCommas } from "./money-converter";



export const InvestorTableDataConverter = (investors: Investor[]): TableData => {
    const bodyRows: Row[] = investors.map(({ userId, email, lname, fname, phone, investments }) => [userId, fname, lname, email, phone, (investments || []).length])
    const head = ['Fornavn', 'Efternavn', 'Email', 'Tel', 'Investments']
    return { head, bodyRows }
}

export const OnlineInvestorTableDataConverter = (investors: ActivityLogWithUser[]): TableData => {
    const bodyRows: Row[] = investors.map(({ userId, lname, fname, chunkUnix }) => [userId, fname + ' ' + lname, (new Date(chunkUnix)).toLocaleTimeString()])
    const head = ['Navn', 'Online at']
    return { head, bodyRows }
}

export const ProjectTableDataConverter = (projects: Project[]): TableData => {
    projects = projects.sort((a, b) => a.content.title.localeCompare(b.content.title))
    const bodyRows: Row[] = projects.map(({ projectId, content, investors, interestCount }) => [projectId, content.title, investors || 0, interestCount || 0])
    const head = ['Name', 'Investments', 'Interests']
    return { head, bodyRows }
}

export const InvestmentTableDataConverter = (projects: InvestmentWithInvestor[]): TableData => {
    const bodyRows: Row[] = projects.map(({ projectId, userId, created, investmentStatus, investmentAmount, currency, fname, lname, email, phone }) =>
        [
            userId + '/' + projectId, fname + ' ' + lname, email, phone,
            (new Date(created)).toLocaleDateString() + ' ' + (new Date(created)).toLocaleTimeString(),
            investmentStatusToText(investmentStatus),
            numberWithCommas(investmentAmount) + ' ' + currency
        ])
    const head = ['Name', 'Email', 'Phone', 'Created', 'Status', 'Amount']
    return { head, bodyRows }
}