import { Breadcrumbs, Typography } from '@material-ui/core';
import * as React from 'react'
import { useHistory } from 'react-router-dom';
import { CustomCard } from '../../components/molecules/Card';
import { Loading } from '../../components/molecules/Loading';
import { GroupingOptions, TimeChart } from '../../components/molecules/TimeChart';
import { DynamicDataTable } from '../../components/organisms/DynamicDataTable';
import { BodyWithTopBar } from '../../components/templates/BodyWithTopBar';
import { CustomSection } from '../../components/templates/Wrappers';
import ApiHooks from '../../globals/ApiHooks';
import { useAppContext } from '../../globals/loadingContext';
import { OnlineInvestorTableDataConverter } from '../../globals/TableDataConverters';
import { Dashboard } from '../../types/API-Repsone-Types';


export const IndexPage: React.FC = () => {
    const setLoading = useAppContext()
    const [dashboardData, setDashboardData] = React.useState<Dashboard>()

    const history = useHistory()

    const goToUser = (userId: string) => {
        history.push('/users/' + userId)
    }

    const getData = async () => {
        setLoading(true)
        const board = await ApiHooks.getDashboard()
        if (board) setDashboardData(board)
        console.log(board)
        setLoading(false)
    }

    React.useEffect(() => {
        getData()
        // eslint-disable-next-line 
    }, [])


    if (!dashboardData) return <Loading />

    return (
        <BodyWithTopBar>
            <CustomSection container column style={{ marginTop: 10 }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary">Dashboard</Typography>
                </Breadcrumbs>
                <h1>Dashboard</h1>
            </CustomSection>
            <CustomSection container style={{ marginTop: 10 }}>
                <CustomCard style={{ maxWidth: 1000 }}>
                    <h4>Activity last 24 hours</h4>
                    <TimeChart grouping={GroupingOptions.HOUR} unixDates={dashboardData.activityLast24Hours.map((i) => i.chunkUnix).reverse()} />
                </CustomCard>
                <CustomCard>
                    <h4>Online users</h4>
                    <DynamicDataTable hideTitle title="Online users" 
                    tableData={OnlineInvestorTableDataConverter(dashboardData.activityLast1Hour)} onRowPress={goToUser} tableSize={dashboardData.activityLast1Hour.length} />
                </CustomCard>

            </CustomSection>

        </BodyWithTopBar>
    )
}
