import * as React from 'react'
import { BodyWithTopBar } from '../../components/templates/BodyWithTopBar';
import { CustomSection } from '../../components/templates/Wrappers';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { useAppContext } from '../../globals/loadingContext';
import ApiHooks from '../../globals/ApiHooks';
import { Project } from '../../types/FB-Project-Types';
import { ProjectList } from '../../components/molecules/ProjectList';

export const ProjectPage: React.FC = () => {

    const [projects, setProjects] = React.useState<Project[]>()
    const setLoading = useAppContext()

    const loadUsersFromDatabase = async () => {
        setLoading(true)
        const result = await ApiHooks.getProjects() as Project[]
        console.log(result)
        if (!result) return
        setLoading(false)
        return result
    }

    // eslint-disable-next-line
    React.useEffect(() => { (async () => { setProjects(await loadUsersFromDatabase()) })() }, [])

    if (!projects) return <></>

    return (
        <BodyWithTopBar>
            <CustomSection container column style={{ marginTop: 10 }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/">Dashboard</Link>
                    <Typography color="textPrimary">Projects</Typography>
                </Breadcrumbs>
            </CustomSection>
            <CustomSection container>
                <ProjectList projects={projects} />
            </CustomSection>
        </BodyWithTopBar>
    )
}
