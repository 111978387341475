import { Investor } from "./FB-User-Types"

export type Investment = {
    consentNewsletter: boolean;
    consentRisk: boolean;
    consentTOS: boolean;
    created: number;
    currency: string;
    exchangeRate: number;
    investmentAmount: number;
    investmentOrigin: string;
    investmentStatus: InvestmentStatus;
    paymentDate: number;
    paymentType: string;
    projectId: string;
    step: number;
    userId: string;
    agreementDate?: number;
    agreementGenerated?: number;
    agreementHash?: string;
    agreementId?: string;
    agreementUrl?: string;
    paymentConfirmedBy?: string;
}

export type InvestmentWithInvestor = Investment & Investor

export enum InvestmentStatus {
    DRAFT = -1, // investering ikke afsluttet af bruger
    PENDING_AML = 0, // Afventer AML/KYC godkendelse
    PENDING_SIGNING = 1, // Afventer kontraktunderskrivelse
    PENDING_PAYMENT = 2, // Afventer betalingsoverførsel
    COMPLETED = 3, // Gennemført
    CANCELLED = 4, // Annulleret af investor
    REJECTED = 5, // Afvist
}

export const investment_status = {
    DRAFT: -1, // investering ikke afsluttet af bruger
    PENDING_AML: 0, // Afventer AML/KYC godkendelse
    PENDING_SIGNING: 1, // Afventer kontraktunderskrivelse
    PENDING_PAYMENT: 2, // Afventer betalingsoverførsel
    COMPLETED: 3, // Gennemført
    CANCELLED: 4, // Annulleret af investor
    REJECTED: 5, // Afvist
}

export const investmentStatusToText = (status: number): string => {
    if (status === -1) return 'Udkast'
    else if (status === 0) return 'Afventer AML/KYC godkendelse'
    else if (status === 1) return 'Afventer kontraktunderskrivelse'
    else if (status === 2) return 'Afventer betalingsoverførsel'
    else if (status === 3) return 'Gennemført'
    else if (status === 4) return 'Annulleret af investor'
    else return 'Afvist'
}