import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import * as React from 'react'
import { useParams } from 'react-router-dom';
import { Loading } from '../../components/molecules/Loading';
import { PaymentsList } from '../../components/organisms/PaymentsList';
import { BodyWithTopBar } from '../../components/templates/BodyWithTopBar';
import { CustomSection } from '../../components/templates/Wrappers';
import { getProjectDetailedById } from '../../globals/ApiHooks';
import { DetailedProject } from '../../types/FB-Project-Types';


export const ProjectPayments: React.FC = () => {
    let { id } = useParams<{ id: string }>();
    const [project, setProject] = React.useState<DetailedProject>()

    React.useEffect(()=>{
        (async () => {
            setProject(await getProjectDetailedById(id))
        })()
    },[id])

    if(!project) return <Loading/>
    return (
        <BodyWithTopBar>
            <CustomSection container column style={{ marginTop: 10 }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/">Dashboard</Link>
                    <Link color="inherit" href="/projects">Projects</Link>
                    <Link color="inherit" href={"/projects/" + id}>{project.content.title}</Link>
                    <Typography color="textPrimary">Payments</Typography>
                </Breadcrumbs>
            </CustomSection>
            <PaymentsList project={project}/>
        </BodyWithTopBar>
    )
}