import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import * as React from 'react'
import { useParams } from 'react-router-dom';
import { InvestmentDetails } from '../../components/organisms/InvestmentDetails';
import { BodyWithTopBar } from '../../components/templates/BodyWithTopBar';
import { CustomSection } from '../../components/templates/Wrappers';


export const DetailedUserInvestment: React.FC = () => {
    let { id, projectId } = useParams<{ id: string, projectId: string }>();
    const [investorName, setInvestorName] = React.useState('')
    const [projectName, setProjectName] = React.useState('')

    return (
        <BodyWithTopBar>
            <CustomSection container column style={{ marginTop: 10 }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/">Dashboard</Link>
                    <Link color="inherit" href="/users">Users</Link>
                    <Link color="inherit" href={"/users/" + id}>{investorName}</Link>
                    <Typography color="textPrimary">{projectName}</Typography>
                </Breadcrumbs>
            </CustomSection>
            <InvestmentDetails setInvestorName={setInvestorName} setProjectName={setProjectName} id={id} projectId={projectId} />
        </BodyWithTopBar>
    )
}