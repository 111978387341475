import * as React from 'react'
import { BodyWithTopBar } from '../../components/templates/BodyWithTopBar';
import { CustomSection } from '../../components/templates/Wrappers';


export const NotFoundPage: React.FC = () => {

    return (
        <BodyWithTopBar>
            <CustomSection container style={{ marginTop: 10 }}>
                <h1>404 NOT FOUND <span role="img" aria-label='sad'>😭</span></h1>
            </CustomSection>
        </BodyWithTopBar>
    )
}
