import { Investor } from "./FB-User-Types"

export enum ActivityType {
    Investment = 'Investment',
    ProjectVisit = 'ProjectVisit',
    FeedVisit = 'Feedvisit'
}

type ChunkUnix = number

export const getCurrentChunkUnix = (): ChunkUnix => {
    //This way a user can only log one item per 5 second. Qucik shifts! 
    return Math.round(Date.now() / 5000) * 5000
}

export type ActivityLog = {
    owner: string;
    activityType: ActivityType;
    chunkUnix: number;
    refrenceId?: string;
}

export type ActivityLogWithUser = ActivityLog & Investor