import * as React from 'react'
import { StatItem } from '../../components/atoms/StatItem';
import { ChipTypes, CustomChip } from '../../components/atoms/StatusChip';
import { ActionList, actionOptions } from '../../components/molecules/ActionList';
import { CustomCard } from '../../components/molecules/Card';
import { Loading } from '../../components/molecules/Loading';
import { CustomSection, VerticalFlexDiv } from '../../components/templates/Wrappers';
import { getProjectById, getUserById } from '../../globals/ApiHooks';
import { numberWithCommas } from '../../globals/money-converter';
import { Investment, InvestmentStatus, investmentStatusToText } from '../../types/FB-Investment-Types';
import { Project } from '../../types/FB-Project-Types';
import { InvestorWithInvestments } from '../../types/FB-User-Types';
import { InvestmentSteps } from '../molecules/InvestmentSteps';


export const investmentStatusToColor = (status: number): ChipTypes => {
    if (status === InvestmentStatus.DRAFT) return ChipTypes.Inactive
    else if (status === InvestmentStatus.COMPLETED) return ChipTypes.Sucess
    else if (status > InvestmentStatus.COMPLETED) return ChipTypes.Error
    else return ChipTypes.Warning
}

export const paymentStatusToColor = (status: number): ChipTypes => {
    if (status === -1) return ChipTypes.Warning
    else return ChipTypes.Sucess
}

type Props = {
    id: string, projectId: string,
    setInvestorName: React.Dispatch<React.SetStateAction<string>>
    setProjectName: React.Dispatch<React.SetStateAction<string>>

}

export const InvestmentDetails: React.FC<Props> = ({ id, projectId, setInvestorName, setProjectName }) => {
    const [investor, setInvestor] = React.useState<InvestorWithInvestments>()
    const [investment, setInvestment] = React.useState<Investment>()
    const [project, setProject] = React.useState<Project>()


    const getInvestor = async () => {
        if (!id) return
        const result = await getUserById(id)
        if (result) {
            setInvestorName(result.fname + ' ' + result.lname)
            setInvestor(result)
            const investmentResult = result.investmentObjects.filter((i) => i.projectId === projectId)[0]
            setInvestment(investmentResult)
            const projectResult = await getProjectById(projectId)
            if (!projectResult) return
            setProjectName(projectResult.content.title)
            setProject(projectResult)

        }
    }

    //eslint-disable-next-line
    React.useEffect(() => { getInvestor() }, [id])

    if (!investor || !investment || !project) return <Loading />

    const name = project.content.title

    const actions = [
        { type: actionOptions.CONTENT, title: "Project", link: '/projects/' + investment.projectId },
        { type: actionOptions.CONTENT, title: "Investor", link: '/users/' + investment.userId }

    ]

    if (investment.agreementUrl) actions.push({ type: actionOptions.CONTENT, title: "Agreement", link: investment.agreementUrl })

    const shares = ((investment.investmentAmount / project.investment.target) * 100).toFixed(2)

    return (

        <CustomSection container>
            <VerticalFlexDiv style={{ flexGrow: 1 }}>
                <CustomCard>
                    <ActionList actions={actions} />
                </CustomCard>
                <CustomCard>
                    <h3>Statistics</h3>
                    <StatItem name='Interests rate' content={(project.investment.return * 100).toFixed(2) + ' %'} />
                    <StatItem name='Return per year'
                        content={numberWithCommas(project.investment.return * investment.investmentAmount) + ' ' + investment.currency} />
                    <StatItem name='Investors in project' content={project.investors} />
                    <StatItem name='Project shares' content={shares + ' %'} />
                </CustomCard>
            </VerticalFlexDiv>
            <VerticalFlexDiv style={{ flexGrow: 2, marginLeft: 14 }}>
                <h1>{numberWithCommas(investment.investmentAmount)} {investment.currency.toUpperCase()}</h1>
                <h4>{name}</h4>
                <h5 style={{ marginBottom: 10 }}>{investor.fname + ' ' + investor.lname} {investor.companyname && ' - '+ investor.companyname +' ('+investor.companyid+')'}</h5>
                
                <div>
                    <CustomChip type={investmentStatusToColor(investment.investmentStatus)}
                        label={investmentStatusToText(investment.investmentStatus).toUpperCase()} />
                    <CustomChip type={paymentStatusToColor(investment.paymentDate)}
                        label={investment.paymentDate === -1 ? "AFVENTER" : "BETALING MODTAGET"} />
                    <CustomChip type={ChipTypes.Inactive} label={'STEP ' + investment.step} />

                </div>
                <InvestmentSteps investment={investment} reload={getInvestor} />
            </VerticalFlexDiv>
        </CustomSection>
    )
}